<template>
  <div>
    <b-container class="" style="min-height:500px;background-color:#fff">
      <div v-if="loading === true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading === false" id="banner" class="banner-inner event">
        <div class="event_caption">
          <div class="slideshow_inner_caption">
            <div class="slideshow_align_caption">
              <h4 class="text-uppercase">{{ $t('Our Community') }}</h4>
              <h1 class="text-uppercase event-title">{{ $t('event') }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mb-5 pt-4">
        <div class="mt-5">
          <h1 class="text-uppercase text-center">{{ $t('Upcoming Events') }}</h1>
          <b-row class="mt-5 d-none justify-content-center">
            <b-col cols="12" sm="4" lg="3">
              <b-img rounded class="w-100" :src="PUBLIC_PATH + 'img/event/event_2019/IMG_3956.jpg'" />
            </b-col>
            <b-col cols="12" sm="8" lg="6">
              <p class="font-weight-bold text-danger-light text-14  mb-0">THU, JUL 22 AT 7 AM UTC+07</p>
              <h5 class="font-weight-bold mb-0">YTTC 200 hr Online-Onsite</h5>
              <p>AdiNath Yoga Ashram - Online Event</p>
              <div class="d-flex">
                <b-button variant="light">
                  <font-awesome-icon :icon="['fas', 'star']"/>
                  Interested
                </b-button>
                <b-button class="ml-2" variant="light">
                  <font-awesome-icon :icon="['fas', 'check-circle']"/>
                  Going
                </b-button>
                <b-button class="ml-2"  variant="light">
                  <font-awesome-icon :icon="['fas', 'envelope']"/>
                  Invite
                </b-button>
                <b-button class="ml-2"  variant="light">
                  <font-awesome-icon :icon="['fas', 'share']"/>
                </b-button>
                <b-button class="ml-2"  variant="light">
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']"/>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-img class="w-100" :src="PUBLIC_PATH + 'img/event/event_plus.jpeg'" />
          <!-- Spiritual -->
          <b-row class="mt-5">
            <b-col cols="1" sm="1">
              <h5 class="font-weight-bold">June</h5>
              <h2 class="font-weight-bold">21</h2 >
            </b-col>
            <b-col cols="12" sm="7">
              <b-img class="w-100" :src="PUBLIC_PATH + 'img/event/event_spiritual.jpeg'" />
            </b-col>
            <b-col cols="12" sm="4">
              <p class="font-weight-bold mb-0">1st Event</p>
              <h2 class="font-weight-bold">{{ $t('Spiritual') }}</h2>
              <p class="mb-0">{{ $t('Community Zoom Meeting') }}</p>
              <p class="mb-0"><b>{{ $t('Topic:')}}</b> {{ $t(' Pray for the Nature and World Peace ☮ celebrating “International Yoga Day”.') }}</p>
              <p class="mb-0"><b>{{ $t('Time:') }}</b> {{ $t('17:00 Hours') }}</p>
              <p>{{ $t('Thailand time zone (GMT +7)') }}</p>
              <p class="text-primary-light"><i>{{ $t('This event is a contribution for all of the people who passed away in Covid-19 situation. We will pray the chants "peace mantra" for their souls to get rest in peace.') }}</i></p>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-gray-light font-weight-bold text-14"  v-b-toggle.event-spiritual><font-awesome-icon class="mr-1" :icon="['fas', 'plus']" />{{ $t('View Event Detail') }}</span>
                <b-button pill class="px-4 py-2 font-weight-bold btn-primary-dark text-14 d-none">{{ $t('Share') }}</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col cols=11>
              <b-collapse id="event-spiritual" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p><b>{{ $t('Sign up eligibility:') }} <u>{{ $t('Everyone') }}</u>.</b> {{ $t('(Free of charge)') }}</p>
                  <p>{{ $t('If you are interested to join this meeting, please send email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
                  <p class="font-weight-bold">{{ $t('For donation:') }}</p>
                  <p class="mb-0">{{ $t('Donation can be made through PayPal or Bank Transfer.') }}</p>
                  <p>{{ $t('You can make donation till') }} <b>30 June 2021</b></p>
                  <p><b>PayPal:</b> worldyogaalliance@gmail.com</p>
                  <p class="font-weight-bold">{{ $t('World Yoga Alliance (INDIA) Bank Account:') }}</p>
                  <p class="mb-0">{{ $t('Bank Name : PUNJAB NATIONAL BANK') }}</p>
                  <p class="mb-0">{{ $t('Branch : SWAROOP NAGAR DELHI-110042.') }}</p>
                  <p class="mb-0">{{ $t('Account Name : WORLD YOGA ALLIANCE.') }}</p>
                  <p class="mb-0">{{ $t('Account No : 6584002100000905.') }}</p>
                  <p class="mb-0">{{ $t('Swift Code : PUNBINBBISB.') }}</p>
                  <p class="mb-0">{{ $t('I.F.S.C Code : PUNB0658400.') }}</p>
                  <p >{{ $t('Account Type : Current Account.') }}</p>
                  <p>{{ $t('* Please send us Bank receipt via email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
                </b-card-body>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- Physical -->
          <b-row class="mt-5">
            <b-col cols="1" sm="1">
              <h5 class="font-weight-bold">July</h5>
              <h2 class="font-weight-bold">6</h2 >
            </b-col>
            <b-col cols="12" sm="7">
              <b-img class="w-100" :src="PUBLIC_PATH + 'img/event/event_physical.jpeg'" />
            </b-col>
            <b-col cols="12" sm="4">
              <p class="font-weight-bold mb-0">2nd Event</p>
              <h2 class="font-weight-bold">{{ $t('Physical') }}</h2>
              <p class="mb-0">{{ $t('Community Zoom Meeting') }}</p>
              <p class="mb-0"><b>{{ $t('Time:') }}</b> {{ $t('17:00 Hours') }}</p>
              <p>{{ $t('Thailand time zone (GMT +7)') }}</p>
              <p class="text-primary-light"><i>{{ $t('Make donations to Support India for Oxygen Machines. Join the Project as active member.') }}</i></p>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-gray-light font-weight-bold text-14"  v-b-toggle.event-physical><font-awesome-icon class="mr-1" :icon="['fas', 'plus']" />{{ $t('View Event Detail') }}</span>
                <b-button pill class="px-4 py-2 font-weight-bold btn-primary-dark text-14 d-none">{{ $t('Share') }}</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col cols=11>
              <b-collapse id="event-physical" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('If you are interest to join this online conference, simply send us email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a> {{ $t('Our team will provide you our meeting code.') }}</p>
                  <p class="font-weight-bold">{{ $t('For donation:') }}</p>
                  <p class="mb-0">{{ $t('Donation can be made through PayPal or Bank Transfer.') }}</p>
                  <p>{{ $t('You can make donation till') }} <b>31 June 2021</b></p>
                  <p><b>PayPal:</b> worldyogaalliance@gmail.com</p>
                  <p class="font-weight-bold">{{ $t('World Yoga Alliance (INDIA) Bank Account:') }}</p>
                  <p class="mb-0">{{ $t('Bank Name : PUNJAB NATIONAL BANK') }}</p>
                  <p class="mb-0">{{ $t('Branch : SWAROOP NAGAR DELHI-110042.') }}</p>
                  <p class="mb-0">{{ $t('Account Name : WORLD YOGA ALLIANCE.') }}</p>
                  <p class="mb-0">{{ $t('Account No : 6584002100000905.') }}</p>
                  <p class="mb-0">{{ $t('Swift Code : PUNBINBBISB.') }}</p>
                  <p class="mb-0">{{ $t('I.F.S.C Code : PUNB0658400.') }}</p>
                  <p >{{ $t('Account Type : Current Account.') }}</p>
                  <p>{{ $t('* Please send us Bank receipt via email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
                </b-card-body>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- Mental -->
          <b-row class="mt-5">
            <b-col cols="1" sm="1">
              <h5 class="font-weight-bold">July</h5>
              <h2 class="font-weight-bold">19</h2 >
            </b-col>
            <b-col cols="12" sm="7">
              <b-img class="w-100" :src="PUBLIC_PATH + 'img/event/event_mental.jpeg'" />
            </b-col>
            <b-col cols="12" sm="4">
              <p class="font-weight-bold mb-0">3rd Event</p>
              <h2 class="font-weight-bold">{{ $t('Mental') }}</h2>
              <p class="mb-0">{{ $t('Community Zoom Meeting') }}</p>
              <p class="mb-0"><b>{{ $t('Topic:')}}</b> {{ $t('How to create Online Courses') }}</p>
              <p class="mb-0"><b>{{ $t('Time:') }}</b> {{ $t('17:00 Hours') }}</p>
              <p>{{ $t('Thailand time zone (GMT +7)') }}</p>
              <p class="text-primary-light"><i>{{ $t('This event will help you in yoga path to have some more skills managing online yoga activities with quality standards.') }}</i></p>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-gray-light font-weight-bold text-14"  v-b-toggle.event-mental><font-awesome-icon class="mr-1" :icon="['fas', 'plus']" />{{ $t('View Event Detail') }}</span>
                <b-button pill class="px-4 py-2 font-weight-bold btn-primary-dark text-14 d-none">{{ $t('Share') }}</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col cols=11>
              <b-collapse id="event-mental" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p><b>{{ $t('Sign up eligibility: WYA Members') }} <u>{{ $t('Only') }}</u>.</b> {{ $t('(Free of charge)') }}</p>
                  <p>{{ $t('If you are interested to join this meeting, please send email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
                  <p class="font-weight-bold">{{ $t('For donation:') }}</p>
                  <p class="mb-0">{{ $t('Donation can be made through PayPal or Bank Transfer.') }}</p>
                  <p>{{ $t('You can make donation till') }} <b>31 June 2021</b></p>
                  <p><b>PayPal:</b> worldyogaalliance@gmail.com</p>
                  <p class="font-weight-bold">{{ $t('World Yoga Alliance (INDIA) Bank Account:') }}</p>
                  <p class="mb-0">{{ $t('Bank Name : PUNJAB NATIONAL BANK') }}</p>
                  <p class="mb-0">{{ $t('Branch : SWAROOP NAGAR DELHI-110042.') }}</p>
                  <p class="mb-0">{{ $t('Account Name : WORLD YOGA ALLIANCE.') }}</p>
                  <p class="mb-0">{{ $t('Account No : 6584002100000905.') }}</p>
                  <p class="mb-0">{{ $t('Swift Code : PUNBINBBISB.') }}</p>
                  <p class="mb-0">{{ $t('I.F.S.C Code : PUNB0658400.') }}</p>
                  <p >{{ $t('Account Type : Current Account.') }}</p>
                  <p>{{ $t('* Please send us Bank receipt via email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
                </b-card-body>
              </b-collapse>
            </b-col>
          </b-row>
        </div>
        <div class="mt-5">
          <h1 class="text-uppercase text-center">{{ $t('Previous Events') }}</h1>
            <div class="text-center">
              <p class="mt-3 mb-0">{{ $t('22 JUNE 2018') }}</p>
              <p>{{ $t('WYA FAMILY EVENT # 1') }}</p>
            </div>
            <CoolLightBox
              key="gallerySlide2018"
              :items="gallerySlide2018"
              :index="index2018"
              @close="index2018 = null">
            </CoolLightBox>
            <b-row>
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[0].id" class="w-100" :src="gallerySlide2018[0].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[1].id" class="w-100" :src="gallerySlide2018[1].src" />
                  </b-col>
                </b-row>
                <b-img @click="index2018 = gallerySlide2018[2].id" class="w-100 mt-4" :src="gallerySlide2018[2].src" />
              </b-col>
              <b-col cols="12" md="6">
                <b-img @click="index2018 = gallerySlide2018[3].id" class="w-100 mb-4" :src="gallerySlide2018[3].src" />
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[4].id" class="w-100" :src="gallerySlide2018[4].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[5].id" class="w-100" :src="gallerySlide2018[5].src" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[6].id" class="w-100" :src="gallerySlide2018[6].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[7].id" class="w-100" :src="gallerySlide2018[7].src" />
                  </b-col>
                </b-row>
                <b-img @click="index2018 = gallerySlide2018[8].id" class="w-100 mt-4" :src="gallerySlide2018[8].src" />
              </b-col>
              <b-col cols="12" md="6">
                <b-img @click="index2018 = gallerySlide2018[9].id" class="w-100 mb-4" :src="gallerySlide2018[9].src" />
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[10].id" class="w-100" :src="gallerySlide2018[10].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2018 = gallerySlide2018[11].id" class="w-100" :src="gallerySlide2018[11].src" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="text-center">
              <p class="mt-3 mb-0">{{ $t('6 JUNE 2019') }}</p>
              <p>{{ $t('WYA FAMILY EVENT # 2') }}</p>
            </div>
            <CoolLightBox
              key="gallerySlide2019"
              :items="gallerySlide2019"
              :index="index2019"
              @close="index2019 = null">
            </CoolLightBox>
            <b-row>
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[0].id" class="w-100" :src="gallerySlide2019[0].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[1].id" class="w-100" :src="gallerySlide2019[1].src" />
                  </b-col>
                </b-row>
                <b-img @click="index2019 = gallerySlide2019[2].id" class="w-100 mt-4" :src="gallerySlide2019[2].src" />
              </b-col>
              <b-col cols="12" md="6">
                <b-img @click="index2019 = gallerySlide2019[3].id" class="w-100 mb-4" :src="gallerySlide2019[3].src" />
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[4].id" class="w-100" :src="gallerySlide2019[4].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[5].id" class="w-100" :src="gallerySlide2019[5].src" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[6].id" class="w-100" :src="gallerySlide2019[6].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[7].id" class="w-100" :src="gallerySlide2019[7].src" />
                  </b-col>
                </b-row>
                <b-img @click="index2019 = gallerySlide2019[8].id" class="w-100 mt-4" :src="gallerySlide2019[8].src" />
              </b-col>
              <b-col cols="12" md="6">
                <b-img @click="index2019 = gallerySlide2019[9].id" class="w-100 mb-4" :src="gallerySlide2019[9].src" />
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[10].id" class="w-100" :src="gallerySlide2019[10].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[11].id" class="w-100" :src="gallerySlide2019[11].src" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[12].id" class="w-100" :src="gallerySlide2019[12].src" />
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-img @click="index2019 = gallerySlide2019[13].id" class="w-100" :src="gallerySlide2019[13].src" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'WYAEvents',
  title: ' | WYA Events',
  data () {
    return {
      loading: true,
      slideIndex: 1,
      index2018: null,
      index2019: null,
      gallerySlide2018: [
        {
          id: 0,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/C0005T01.jpg',
          description: '1'
        },
        {
          id: 1,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/C0010T01.jpg',
          description: '2'
        },
        {
          id: 2,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/C0016T01.jpg',
          description: '3'
        },
        {
          id: 3,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/C0032T01.jpg',
          description: '4'
        },
        {
          id: 4,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5006.jpg',
          description: '5'
        },
        {
          id: 5,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5062.jpg',
          description: '6'
        },
        {
          id: 6,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5116.jpg',
          description: '7'
        },
        {
          id: 7,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5157.jpg',
          description: '8'
        },
        {
          id: 8,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5175.jpg',
          description: '9'
        },
        {
          id: 9,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5185.jpg',
          description: '10'
        },
        {
          id: 10,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5203.jpg',
          description: '11'
        },
        {
          id: 11,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2018/IMG_5219.jpg',
          description: '12'
        }
      ],
      gallerySlide2019: [
        {
          id: 0,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3574.jpg',
          description: '1'
        },
        {
          id: 1,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3578.jpg',
          description: '2'
        },
        {
          id: 2,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3581.jpg',
          description: '3'
        },
        {
          id: 3,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3642.jpg',
          description: '4'
        },
        {
          id: 4,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3681.jpg',
          description: '5'
        },
        {
          id: 5,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3685.jpg',
          description: '6'
        },
        {
          id: 6,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3691.jpg',
          description: '7'
        },
        {
          id: 7,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3730.jpg',
          description: '8'
        },
        {
          id: 8,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3773.jpg',
          description: '9'
        },
        {
          id: 9,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3889.jpg',
          description: '10'
        },
        {
          id: 10,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3902.jpg',
          description: '11'
        },
        {
          id: 11,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3927.jpg',
          description: '12'
        },
        {
          id: 12,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3956.jpg',
          description: '13'
        },
        {
          id: 13,
          src: process.env.VUE_APP_BASE_PATH + 'img/event/event_2019/IMG_3976.jpg',
          description: '14'
        }
      ]
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    plusSlides (n) {
      this.showSlides(this.slideIndex += n)
    },
    currentSlide (n) {
      this.showSlides(this.slideIndex = n)
    },
    showSlides (n) {
      var i
      var slides = document.getElementsByClassName('mySlides')
      var dots = document.getElementsByClassName('demo')
      var captionText = document.getElementById('caption')
      if (n > slides.length) { this.slideIndex = 1 }
      if (n < 1) { this.slideIndex = slides.length }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none'
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '')
      }
      slides[this.slideIndex - 1].style.display = 'block'
      dots[this.slideIndex - 1].className += ' active'
      captionText.innerHTML = dots[this.slideIndex - 1].alt
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  .event-title {
    font-size: 100px;
    letter-spacing: 30px;
  }
  @media screen and (max-width: 767.98px) {
    .event-title {
      font-size: 50px;
    }
  }
</style>
